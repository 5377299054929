import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { endOfToday, startOfToday } from 'date-fns';
import { RFPStatus } from '../../enums/rfp-status';
import { GrantCycleScoringStatus } from '../../enums/scoring.enum';
import { ESortDirection } from '../../enums/sort-direction';
import {
  IListResults,
  PaginatedResult
} from '../../interfaces/IGenerics.interfaces';
import {
  IInvitation,
  IRfpInvitationResponse
} from '../../interfaces/IInvitation.interfaces';
import {
  IGetListRfpsResponse,
  IGetRfpsWithInvitationStatusResponse,
  IRfp
} from '../../interfaces/IRfp.interfaces';
import { IRfpSummary } from '../../interfaces/IRfpSummary.interfaces';
import { IRfpScoringInfo } from '../../interfaces/IScoring.interfaces';
import { api } from './api.service';

type listCurrentParams = {
  rolling: boolean | null;
  granteeId: number | string | undefined;
  status?: RFPStatus;
};

export type RfpsForScoringQueryParams = {
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortDir?: ESortDirection;
  status?: GrantCycleScoringStatus;
};

export class RfpService {
  private static baseRoute = '/api/rfp';

  public static async list(): Promise<IListResults<IRfp>> {
    const res = await api.get(`${this.baseRoute}/list`);
    return res.data as IListResults<IRfp>;
  }

  public static async getRfpsWithInvitationStatus(
    funderId: number,
    granteeId: number
  ): Promise<IRfp[]> {
    const response = await api.get<IGetRfpsWithInvitationStatusResponse>(
      `${this.baseRoute}/with-invitation-status`,
      {
        params: { funderId, granteeId }
      }
    );

    return response.data?.data;
  }

  public static async bulkInviteToRfp(
    granteeId: number,
    rfpIds: number[]
  ): Promise<AxiosResponse<IRfpInvitationResponse>> {
    const response = await api.post<IRfpInvitationResponse>(
      `${this.baseRoute}/bulk-invitations`,
      {
        granteeId,
        rfpIds
      }
    );

    return response;
  }

  public static async undoInviteToRfp(
    granteeId: number,
    rfpIds: number[]
  ): Promise<AxiosResponse<IRfpInvitationResponse>> {
    const response = await api.post<IRfpInvitationResponse>(
      `${this.baseRoute}/undo-bulk-invitations`,
      {
        granteeId,
        rfpIds
      }
    );

    return response;
  }

  public static async listCurrent(
    params: listCurrentParams,
    signal?: AxiosRequestConfig
  ): Promise<IGetListRfpsResponse> {
    const res = await api.get(`${this.baseRoute}/list-current`, { params });
    return res.data as IGetListRfpsResponse;
  }

  public static async inviteOnly(
    granteeId: string
  ): Promise<IListResults<IRfp>> {
    const endOfDay = endOfToday();
    const startOfDay = startOfToday();
    const res = await api.get(`${this.baseRoute}/list`, {
      params: {
        invite: granteeId,
        announcement_date: startOfDay.toISOString(),
        deadline_date: endOfDay.toISOString(),
        status: RFPStatus.APPROVED
      }
    });
    return res.data as IListResults<IRfp>;
  }

  public static async getAllFunderRfps(
    signal?: AxiosRequestConfig
  ): Promise<IRfp[]> {
    const res = await api.get(`${this.baseRoute}/funder`);
    return res.data as IRfp[];
  }

  public static async getById(
    id: number,
    signal?: AxiosRequestConfig
  ): Promise<IRfp> {
    const res = await api.get(`${this.baseRoute}/${id}`);
    return res.data as IRfp;
  }

  public static async update(
    cycleId: number,
    rfp: Partial<IRfp>
  ): Promise<IRfp> {
    const res = await api.patch(`${this.baseRoute}/${cycleId}`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async create(rfp: any): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async duplicate(rfp: {
    id: number;
    funder_id: number;
  }): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}/duplicate`, {
      ...rfp
    });
    return res.data as IRfp;
  }

  public static async createInvitation(
    grantCycleId: number,
    granteeId: number
  ): Promise<IInvitation> {
    const payload = {
      grantee_id: granteeId,
      rfp_id: grantCycleId
    };
    const res = await api.post(`${this.baseRoute}/invitations`, payload);

    return res.data as IInvitation;
  }

  public static async delete(id: number): Promise<IRfp> {
    const res = await api.delete(`${this.baseRoute}/${id}`);
    return res.data as IRfp;
  }

  public static async deleteInvitation(
    invitationId: number
  ): Promise<{ deletedInvite: boolean }> {
    const res = await api.delete(
      `${this.baseRoute}/invitation/${invitationId}`
    );
    return res.data as { deletedInvite: boolean };
  }

  public static async getByFunderGroupId(id: number): Promise<IRfp[]> {
    const res = await api.get(`${this.baseRoute}/funder/${id}`);
    return res.data as IRfp[];
  }

  public static async getRfpsForScoring(
    options: AxiosRequestConfig = {}
  ): Promise<PaginatedResult<IRfp>> {
    const res = await api.get(`${this.baseRoute}/scoring`, options);
    return res.data as PaginatedResult<IRfp>;
  }

  public static async getRfpScoringInfo(id: number): Promise<IRfpScoringInfo> {
    const res = await api.get(`${this.baseRoute}/${id}/scoring`);
    return res.data as IRfpScoringInfo;
  }

  public static async confirmEligibility({
    granteeId,
    id
  }: {
    granteeId: number;
    id: number;
  }): Promise<IRfp> {
    const res = await api.post(`${this.baseRoute}/${id}/confirm-eligibility`, {
      granteeId
    });
    return res.data as IRfp; // TBD
  }

  public static async getRfpSummary(
    options: AxiosRequestConfig = {}
  ): Promise<PaginatedResult<IRfpSummary> | null> {
    try {
      const { data: res } = await api.get(`${this.baseRoute}/summary`, options);
      return res.data as PaginatedResult<IRfpSummary>;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
